import { FirebaseAuthContext } from "contexts";
import { Auth, getAuth } from "firebase/auth";
import { useFirebaseApp } from "hooks";
import { useEffect, useState } from "react";
import { ReactChild } from "react";

interface IProps {
  children: ReactChild;
}
export const FirebaseAuthHandler = ({ children }: IProps) => {
  const firebaseApp = useFirebaseApp();
  const [firebaseAuth, setFirebaseAuth] = useState<Auth>();

  useEffect(() => {
    if (!firebaseApp) {
      setFirebaseAuth(undefined);
    } else {
      setFirebaseAuth(getAuth(firebaseApp));
    }
  }, [firebaseApp, setFirebaseAuth]);

  return (
    <FirebaseAuthContext.Provider value={firebaseAuth}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};
