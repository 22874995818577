import { Spinner } from "components";
import { useAuthUser, useDatabase, useRoles } from "hooks";
import { FormEvent, useEffect, useState } from "react";
import { IUser } from "types";
import * as PERMISSIONS from "constants/permissions";
import { updateUser } from "utils/data";

interface IProps {
  user?: IUser;
  onSubmit?: () => void;
}
export const UserForm = ({ user: userProp, onSubmit }: IProps) => {
  let user_: IUser = {
    uid: "",
    name: "",
    email: "",
    role: "",
    ...userProp,
  };

  const [name, setName] = useState(user_.name);
  const [email, setEmail] = useState(user_.email);
  const [role, setRole] = useState(user_.role);
  const availableRoles = useRoles();

  const [authUser] = useAuthUser();
  const database = useDatabase();

  useEffect(() => {
    const user_ = {
      name: "",
      email: "",
      role: "",
      ...userProp,
    };

    setName(user_.name);
    setEmail(user_.email);
    setRole(user_.role);
  }, [userProp]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!database || !userProp) return;
    const user: IUser = { uid: userProp.uid, name, email, role };
    await updateUser(database, user);
    onSubmit?.();
  };

  const restrictedRoles = availableRoles
    .filter((r) => r.restricted)
    .map((r) => r.name);

  if (!authUser) {
    return <Spinner />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <h1>Endre bruker</h1>
      <label htmlFor="name">Navn</label>
      <input
        name="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        type="text"
        placeholder="Full Name"
      />

      <label htmlFor="email">E-post</label>
      <input
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="text"
        placeholder="Email Address"
      />

      <label htmlFor="role">Rolle</label>
      <select
        value={role}
        onChange={(e) => setRole(e.target.value)}
        name="role"
        disabled={
          restrictedRoles.includes(role) &&
          !authUser.permissions[PERMISSIONS.SET_RESTRICTED_ROLES]
        }
      >
        <option
          value=""
          title="Ingen rolle - brukeren vil ikke ha tilgang til noen funksjoner."
        >
          Ingen rolle
        </option>
        {availableRoles.map((r) => (
          <option
            value={r.name}
            key={r.name}
            title={r.description}
            disabled={
              r.restricted &&
              !authUser.permissions[PERMISSIONS.SET_RESTRICTED_ROLES]
            }
          >
            {r.name}
          </option>
        ))}
      </select>

      <button className="btn" type="submit">
        Lagre
      </button>
    </form>
  );
};
