import { useDatabase, useMembers } from "hooks";
import { useSemester } from "hooks/useSemester";
import { useState } from "react";
import { IMember, ISemester, PaymentStatus } from "types";
import { updateSemester } from "utils/data";
import { PaymentForm } from "./PaymentForm";
import { SemesterSelector } from "./SemesterSelector";

export const Payment = () => {
  const members = useMembers();
  const [semesterId, setSemesterId] = useState<string | undefined>();
  const semester = useSemester(semesterId);
  const database = useDatabase();

  const handlePaymentChange = async (member: IMember, value: PaymentStatus) => {
    if (!semester || !database) return;

    if (value === "paid") {
      semester.payees[member.id] = member.id;
    } else if (value === "not-paid") {
      delete semester.payees[member.id];
    }

    await updateSemester(database, semester);
  };

  const handleSemesterSelect = (semester: ISemester) => {
    setSemesterId(semester.id);
  };

  return (
    <div className="content">
      {!semester && (
        <SemesterSelector onSemesterSelect={handleSemesterSelect} />
      )}
      {semester && (
        <PaymentForm
          onPaymentChange={handlePaymentChange}
          members={members}
          semester={semester}
          onChangeSemester={() => setSemesterId(undefined)}
        />
      )}
    </div>
  );
};
