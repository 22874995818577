import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useDatabase } from "./useDatabase";

export const useEventTypes = (): string[] | undefined => {
  const [eventTypes, setEventTypes] = useState<string[] | undefined>();
  const database = useDatabase();

  useEffect(() => {
    if (!database) return;

    const unsubscribe = onValue(ref(database, "event_types"), (snapshot) => {
      setEventTypes(Object.keys(snapshot.val()));
    });

    return unsubscribe;
  }, [database]);

  return eventTypes;
};
