import { Spinner } from "components";
import { Modal } from "components/Modal";
import { useSemesters } from "hooks";
import { useState } from "react";
import { ISemester } from "types";
import { formatDate } from "utils/dates";
import { SemesterForm } from "./SemesterForm";

interface ISemesterSelectorProps {
  onSemesterSelect: (semester: ISemester) => void;
}
export const SemesterSelector = ({
  onSemesterSelect,
}: ISemesterSelectorProps) => {
  const [modalActive, setModalActive] = useState(false);
  const [editSemester, setEditSemester] = useState<ISemester | undefined>();
  const semesters = useSemesters();

  return (
    <div className="semester-selector">
      <Modal active={modalActive} onClose={() => setModalActive(false)}>
        <SemesterForm
          semester={editSemester}
          onSubmit={(semester) => {
            onSemesterSelect(semester);
            setModalActive(false);
          }}
        />
      </Modal>
      <div className="semester-selector__button-bar">
        <button
          className="btn"
          onClick={() => {
            setModalActive(true);
            setEditSemester(undefined);
          }}
        >
          Nytt semester
        </button>
      </div>
      {!semesters && <Spinner />}
      {!!semesters && (
        <SemesterList
          semesters={semesters}
          onSemesterSelect={onSemesterSelect}
        />
      )}
    </div>
  );
};

interface ISemesterListProps {
  semesters: ISemester[];
  onSemesterSelect: (semester: ISemester) => void;
}
const SemesterList = ({ semesters, onSemesterSelect }: ISemesterListProps) => {
  return (
    <table className="table-full-width table-hor-lines-between table-last-td-right">
      <thead>
        <tr>
          <th>Tittel</th>
          <th className="desktop-only">Startdato</th>
          <th className="desktop-only">Sluttdato</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {semesters.map((semester) => (
          <SemesterListElement
            semester={semester}
            key={semester.id}
            onSemesterSelect={onSemesterSelect}
          />
        ))}
      </tbody>
    </table>
  );
};

interface ISemesterListElementProps {
  semester: ISemester;
  onSemesterSelect: (semester: ISemester) => void;
}
const SemesterListElement = ({
  semester,
  onSemesterSelect,
}: ISemesterListElementProps) => {
  return (
    <tr>
      <td>{semester.title}</td>
      <td className="desktop-only">{formatDate(semester.startDate)}</td>
      <td className="desktop-only">{formatDate(semester.endDate)}</td>
      <td>
        <button
          onClick={() => onSemesterSelect(semester)}
          className="btn btn-small"
        >
          Velg
        </button>
      </td>
    </tr>
  );
};
