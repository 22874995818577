import { useAuthUser } from "hooks";

export const Home = () => {
  const [authUser] = useAuthUser();

  return (
    <div className="content">
      <h1>
        Velkommen til <strong>YadahReg!</strong>
      </h1>
      {!!authUser && authUser.permissions ? (
        <p>
          Bruk toppmenyen for å navigere til de forskjellige verktøyene på
          siden.
        </p>
      ) : (
        <p>
          Brukeren din er ikke aktivert. Ta kontakt med en administrator for å
          få fikset dette.
        </p>
      )}
    </div>
  );
};
