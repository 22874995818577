import { useState } from "react";
import { Bar } from "./Bar";
import { Network } from "./Network";

type PlotType = "network" | "bar";
export type DataType = "voice_groups";

export const Plots = () => {
  const [plotType, setPlotType] = useState<PlotType>("network");
  const [dataType, setDataType] = useState<DataType>("voice_groups");

  return (
    <div>
      <select
        value={plotType}
        onChange={(e) => setPlotType(e.target.value as PlotType)}
      >
        <option value="network">Nettverk</option>
        <option value="bar">Stolper</option>
      </select>
      <select
        value={dataType}
        onChange={(e) => setDataType(e.target.value as DataType)}
      >
        <option value="voice_groups">Stemmegrupper</option>
      </select>
      {plotType === "network" && <Network dataType={dataType} />}
      {plotType === "bar" && <Bar dataType={dataType} />}
    </div>
  );
};
