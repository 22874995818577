import { useCallback } from "react";
import { signOut } from "firebase/auth";
import { useFirebaseAuth } from "hooks/useFirebaseAuth";

interface IProps {
  buttonClass?: string;
}
export const SignOutButton = ({ buttonClass }: IProps) => {
  const auth = useFirebaseAuth();
  const doSignOut = useCallback(() => {
    if (!auth) {
      return;
    }
    signOut(auth);
  }, [auth]);

  return (
    <button type="button" onClick={doSignOut} className={buttonClass}>
      Logg ut
    </button>
  );
};
