import { ButtonSelect } from "components";
import { Fragment, KeyboardEvent, useState } from "react";
import { IButtonSelectOption, IMember, ISemester, PaymentStatus } from "types";

const PAYMENT_OPTIONS: IButtonSelectOption[] = [
  {
    value: "paid",
    text: (
      <i
        className="fas fa-money-bill"
        style={{
          fontSize: "1.5em",
          lineHeight: "1em",
        }}
      />
    ),
    tooltip: "Har betalt",
  },
  {
    value: "not-paid",
    text: (
      <span
        className="fa-stack"
        style={{
          fontSize: "0.75em",
        }}
      >
        <i className="fas fa-money-bill fa-stack-1x" />
        <i className="fas fa-ban fa-stack-2x" />
      </span>
    ),
    tooltip: "Har ikke betalt",
  },
];

interface IProps {
  semester: ISemester;
  members?: IMember[];
  onPaymentChange: (member: IMember, status: PaymentStatus) => void;
  onChangeSemester: () => void;
}
export const PaymentForm = ({
  semester,
  members,
  onPaymentChange,
  onChangeSemester,
}: IProps) => {
  const [filter, setFilter] = useState("");

  const getStatus = (member: IMember) => {
    if (semester) {
      if (semester.payees && !!semester.payees[member.id]) {
        return "paid";
      }
    }
    return "not-paid";
  };

  const isMatch = (filter: string, name: string) => {
    const regex = new RegExp("(.*)" + filter + "(.*)", "i");
    return regex.test(name);
  };

  const handleFilterKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      const matchingMembers = members?.filter((member) => {
        const res = isMatch(filter, member.firstName + " " + member.lastName);
        return res;
      });
      if (matchingMembers?.length === 1) {
        onPaymentChange(matchingMembers?.[0], "paid");

        setTimeout(() => setFilter(""), 600);
      }
    }
  };

  return (
    <div className="payment-form">
      <div className="payment-form__header">
        <h1>{semester.title}</h1>
      </div>

      <button
        className="btn btn-link payment-form__change-semester"
        onClick={onChangeSemester}
      >
        Endre semester
      </button>

      <input
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        onKeyDown={handleFilterKeyDown}
        name="filter"
        type="text"
        placeholder="Søk..."
      />

      <table className="payment-form__member-table table-full-width table-hor-lines-between table-last-td-right">
        <tbody>
          {members?.map((member) => (
            <Fragment key={member.id}>
              {(!filter ||
                isMatch(filter, member.firstName + " " + member.lastName)) && (
                <tr>
                  <td className="payment-form__member-name">
                    {member.firstName} {member.lastName}
                  </td>
                  <td className="payment-form__buttons">
                    <ButtonSelect
                      options={PAYMENT_OPTIONS}
                      onChange={(e) =>
                        onPaymentChange(member, e.value as PaymentStatus)
                      }
                      value={getStatus(member)}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
