import { Spinner } from "components";
import { useMembers } from "hooks";

export const Allergies = () => {
  const members = useMembers();

  const membersWithAllergies = members?.filter((member) => !!member.allergies);

  const content = !members ? (
    <Spinner />
  ) : !membersWithAllergies?.length ? (
    <p>Fant ingen medlemmer med allergier</p>
  ) : (
    <table className="table-full-width table-hor-lines-between">
      <thead>
        <tr>
          <th>Navn</th>
          <th>Allergi(er)</th>
        </tr>
      </thead>
      <tbody>
        {membersWithAllergies.map((member) => (
          <tr key={member.id}>
            <td>
              {member.firstName} {member.lastName}
            </td>
            <td>{member.allergies}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="content">
      <h1>Allergier</h1>
      {content}
    </div>
  );
};
