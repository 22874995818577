import { useEffect, useState } from "react";
import { ISemester } from "types";
import { useSemesters } from "./useSemesters";

export const useLastSemester = () => {
  const semesters = useSemesters();
  const [lastSemester, setLastSemester] = useState<ISemester | undefined>();

  useEffect(() => {
    if (!semesters) return;

    setLastSemester(
      semesters.reduce((a, b) => (a.endDate > b.endDate ? a : b))
    );
  }, [semesters]);

  return lastSemester;
};
