import { IMember } from "types";

export const formatDate = (date: Date): string => {
  return `${date.getUTCDate().toString().padStart(2, "0")}.${(
    date.getUTCMonth() + 1
  )
    .toString()
    .padStart(2, "0")}.${date.getUTCFullYear()}`;
};

export const hasBirthday = (member: IMember) => {
  const today = new Date();
  return (
    today.getDate() === member.birthday.getDate() &&
    today.getMonth() === member.birthday.getMonth()
  );
};
