import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { ISemester } from "types";
import { ISemesterDbo } from "types/contractTypes";
import { useDatabase } from "./useDatabase";
import { mapSemester } from "./useSemesters";

export const useSemester = (
  semesterId: string | undefined
): ISemester | undefined => {
  const [semester, setSemester] = useState<ISemester | undefined>();
  const database = useDatabase();

  useEffect(() => {
    if (!semesterId) {
      setSemester(undefined);
      return;
    }
    if (!database) return;

    const unsubscribe = onValue(
      ref(database, `semesters/${semesterId}`),
      (snapshot) => {
        const semesterDbo = snapshot.val() as ISemesterDbo;
        const semester: ISemester = mapSemester(semesterId, semesterDbo);
        setSemester(semester);
      }
    );

    return unsubscribe;
  }, [database, semesterId]);

  return semester;
};
