import * as d3 from "d3";

export const generateNetwork = (members, svgRef) => {
  const SOPRANO_ID = "Sopran";
  const ALTO_ID = "Alt";
  const TENOR_ID = "Tenor";
  const BASS_ID = "Bass";
  const VET_IKKE_ID = "Vet ikke";

  let counter = 0;
  let nodes = members.map((m) => ({ ...m, type: "person", id: counter++ }));
  let links = nodes.map((d) => ({
    source: d.id,
    target: ["", "Null"].includes(d.voiceGroup) ? VET_IKKE_ID : d.voiceGroup,
  }));
  nodes = [
    ...nodes,
    { id: SOPRANO_ID, name: "Sopran", type: "group" },
    { id: ALTO_ID, name: "Alt", type: "group" },
    { id: TENOR_ID, name: "Tenor", type: "group" },
    { id: BASS_ID, name: "Bass", type: "group" },
    { id: VET_IKKE_ID, name: "Vet ikke", type: "group" },
  ];

  const randomForce =
    (movement = 1.0) =>
    () => {
      for (var i = 0, n = nodes.length, node, k = 0.1 * movement; i < n; ++i) {
        node = nodes[i];
        node.vx += k * (Math.random() - 0.5);
        node.vy += k * (Math.random() - 0.5);
      }
    };

  const simulation = d3
    .forceSimulation(nodes)
    .alphaMin(0)
    .alphaTarget(0.1)
    .force(
      "link",
      d3.forceLink(links).id((d) => d.id)
    )
    .force("charge", d3.forceManyBody().strength(-100))
    .force("x", d3.forceX().strength(0.12))
    .force("y", d3.forceY().strength(0.12))
    .force("random", randomForce(1));

  const svg = d3.select(svgRef.current);
  svg.selectAll("*").remove();
  const drag = (simulation) => {
    const dragstarted = (event, d) => {
      d.fx = d.x;
      d.fy = d.y;
    };

    const dragged = (event, d) => {
      d.fx = event.x;
      d.fy = event.y;
    };

    const dragended = (event, d) => {
      d.fx = null;
      d.fy = null;
    };

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  const link = svg
    .append("g")
    .attr("stroke", "#999")
    .attr("stroke-opacity", 0.6)
    .selectAll("line")
    .data(links)

    .join("line")
    .call(drag(simulation));

  const node = svg
    .append("g")
    .attr("stroke", "#FFF")
    .attr("stroke-width", 1.5)
    .selectAll("circle")
    .data(nodes)
    .join("circle")
    .attr("r", (d) => (d.type === "group" ? 15 : 6))
    .attr("fill", (d) => d.fill ?? "#000")
    .attr("title", (d) => d.name)
    .style("cursor", "pointer")
    .call(drag(simulation));

  const labels = svg
    .append("g")
    .selectAll("text")
    .data(nodes.filter((d) => d.type === "group"))
    .join("text")
    .style("fill", "#000")
    .style("stroke", "#fff")
    .style("stroke-width", 0.1)
    .style("font-size", "1.5em")
    .text((d) => d.name)
    .call(drag(simulation));

  simulation.on("tick", () => {
    link
      .attr("x1", (d) => d.source.x)
      .attr("y1", (d) => d.source.y)
      .attr("x2", (d) => d.target.x)
      .attr("y2", (d) => d.target.y);

    node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

    labels.attr("x", (d) => d.x + 15).attr("y", (d) => d.y + 5);
  });

  return () => {
    simulation.stop();
    svg.selectAll("*").remove();
  };
};
