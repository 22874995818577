import * as d3 from "d3";
import { groupBy } from "utils/groupBy";

export const generateBar = (members, svgRef, initialSize) => {
  members = members.map((m) => ({
    ...m,
    voiceGroup: ["", "Null"].includes(m.voiceGroup) ? "Vet ikke" : m.voiceGroup,
  }));
  const groups = groupBy(members, (m) => m.voiceGroup);
  console.log(groups);
  const data = groupBy(members, (m) => m.voiceGroup).map((g) => ({
    name: g[0],
    value: g[0].length,
  }));

  const svg = d3.select(svgRef.current);
  svg.selectAll("*").remove();

  const x = d3
    .scaleBand()
    .range([0, initialSize])
    .domain(data.map((d) => d.name))
    .padding(0.2);

  svg
    .append("g")
    .attr("transform", `translate(0, ${initialSize})`)
    .call(d3.axisBottom(x))
    .selectAll("text")
    .attr("transform", "translate(-10,0) rotate(-45)")
    .style("text-anchor", "end");

  var y = d3.scaleLinear().domain([0, 30]).range([initialSize, 0]);
  svg.append("g").call(d3.axisLeft(y));

  svg
    .selectAll("mybar")
    .data(data)
    .enter()
    .append("rect")
    .attr("x", (d) => x(d.name))
    .attr("y", (d) => y(d.value))
    .attr("width", x.bandwidth())
    .attr("height", (d) => initialSize - y(d.value))
    .attr("fill", "#69b3a2");

  return () => {
    svg.selectAll("*").remove();
  };
};
