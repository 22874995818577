import { Database, push, ref, remove, update } from "firebase/database";
import { IEvent, IMember, IRole, ISemester, IUser } from "types";
import {
  IEventDbo,
  IMemberDbo,
  IRoleDbo,
  ISemesterDbo,
  IUserDbo,
} from "types/contractTypes";

export const updateEvent = async (database: Database, event: IEvent) => {
  const saveEvent: IEventDbo = {
    date: event.date.toISOString().split("T")[0],
    title: event.title,
    type: event.type,
    attendants: event.attendants,
    non_attendants: event.nonAttendants,
  };

  await update(ref(database, `events/${event.id}`), saveEvent);
};

export const createEvent = async (
  database: Database,
  event: IEvent
): Promise<string> => {
  const saveEvent: IEventDbo = {
    title: event.title,
    date: event.date.toISOString().split("T")[0],
    type: event.type,
  };

  const eventRef = await push(ref(database, "events"), saveEvent);
  return eventRef.key ?? "";
};

export const deleteEvent = async (database: Database, eventId: string) => {
  await remove(ref(database, `events/${eventId}`));
};

export const updateMember = async (database: Database, member: IMember) => {
  await update(ref(database, `members/${member.id}`), mapMember(member));
};

export const createMember = async (
  database: Database,
  member: IMember
): Promise<string> => {
  const memberRef = await push(ref(database, "members"), mapMember(member));
  return memberRef.key ?? "";
};

export const deleteMember = async (database: Database, memberId: string) => {
  await remove(ref(database, `members/${memberId}`));
};

const mapMember = (member: IMember): IMemberDbo => ({
  active: member.active,
  address: member.address ?? "",
  allergies: member.allergies,
  birthday: member.birthday.toISOString().split("T")[0],
  created_at: member.createdAt.toISOString(),
  email: member.email,
  first_name: member.firstName,
  gender: member.gender,
  last_name: member.lastName,
  phone: member.phone,
  voice_group: member.voiceGroup,
});

export const createSemester = async (
  database: Database,
  semester: ISemester
): Promise<string> => {
  const semesterRef = await push(
    ref(database, "semesters"),
    mapSemester(semester)
  );
  return semesterRef.key ?? "";
};

export const updateSemester = async (
  database: Database,
  semester: ISemester
) => {
  await update(
    ref(database, `semesters/${semester.id}`),
    mapSemester(semester)
  );
};

const mapSemester = (semester: ISemester): ISemesterDbo => ({
  start_date: semester.startDate.toISOString().split("T")[0],
  end_date: semester.endDate.toISOString().split("T")[0],
  title: semester.title,
  payees: semester.payees,
});

export const updateRole = async (database: Database, role: IRole) => {
  const roleDbo: IRoleDbo = {
    description: role.description,
    restricted: role.restricted,
    permissions: role.permissions,
  };

  await update(ref(database, `roles/${role.name}`), roleDbo);
};

export const updateUser = async (database: Database, user: IUser) => {
  const userDbo: IUserDbo = {
    email: user.email,
    name: user.name,
    role: user.role,
  };

  await update(ref(database, `users/${user.uid}`), userDbo);
};
