import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { IUser } from "types";
import { IUserDbo } from "types/contractTypes";
import { useDatabase } from "./useDatabase";

export const useUsers = (): IUser[] => {
  const [users, setUsers] = useState<IUser[]>([]);
  const database = useDatabase();

  useEffect(() => {
    if (!database) return;

    const unsubscribe = onValue(ref(database, "users"), (snapshot) => {
      const usersObject = snapshot.val();
      const users = Object.keys(usersObject)
        .map((key) => {
          const dbo: IUserDbo = usersObject[key];
          const user: IUser = {
            uid: key,
            name: dbo.name,
            email: dbo.email,
            role: dbo.role,
          };
          return user;
        })
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (b.name < a.name) return 1;
          return 0;
        });
      setUsers(users);
    });
    return unsubscribe;
  }, [database]);

  return users;
};
