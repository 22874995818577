import { Navigation } from "components";
import { useAuthUser } from "hooks";
import { useUserSettings } from "hooks/useUserSettings";
import { createUseStyles } from "react-jss";
import { IUserSettings } from "types";
import { RoutesList } from "./RoutesList";

interface IStyleProps {
  settings: IUserSettings;
}
const useStyles = createUseStyles({
  wrapper: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    background: ({ settings }: IStyleProps) =>
      settings.darkMode ? "#555" : "white",
    color: ({ settings }: IStyleProps) =>
      settings.darkMode ? "white" : "black",
    "& > :last-child": {
      flexGrow: 1,
    },
  },
});

export const App = () => {
  const user = useAuthUser();
  const showNavigation = !!user;
  const isLocal =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1";
  const isProd = process.env.NODE_ENV === "production";
  const prodOnLocal = isLocal && isProd;

  if (isLocal) {
    if (!document.title.startsWith("[local] ")) {
      document.title = "[local] " + document.title;
    }
  } else if (!isProd && !document.title.startsWith("[test] ")) {
    document.title = "[test] " + document.title;
  }

  const settings = useUserSettings();
  const classes = useStyles({ settings });
  return (
    <div className={classes.wrapper}>
      <div className={`top-bar ${showNavigation ? "" : "hidden"}`}>
        <Navigation />
      </div>
      <div className="content-wrapper">
        {prodOnLocal && (
          <span style={{ fontWeight: "bold", color: "red" }}>
            You are running against the production environment on localhost! For
            development, the testing environment should be used to avoid
            accidental data corruption or loss.
          </span>
        )}
        <RoutesList />
      </div>
    </div>
  );
};
