import { useState } from "react";
import RegistrationForm from "./RegistrationForm";
import EventSelector from "./EventSelector";
import { useMembers, useEvent, useLastSemester, useDatabase } from "hooks";
import { updateEvent } from "utils/data";
import { RegistrationStatus } from "types";
import { Spinner } from "components";

export const Registration = () => {
  const [eventId, setEventId] = useState<string | undefined>(undefined);
  const members = useMembers();
  const event = useEvent(eventId);
  const semester = useLastSemester();
  const database = useDatabase();

  const handleRegistrationChange = (
    memberId: string,
    value: RegistrationStatus
  ) => {
    if (!event || !database) {
      return;
    }
    if (!event["attendants"]) {
      event.attendants = {};
    }
    if (!event["nonAttendants"]) {
      event["nonAttendants"] = {};
    }

    if (value === "present") {
      event.attendants[memberId] = memberId;
      delete event.nonAttendants[memberId];
    } else if (value === "notified") {
      delete event.attendants[memberId];
      event.nonAttendants[memberId] = memberId;
    } else if (value === "not-present") {
      delete event.attendants[memberId];
      delete event.nonAttendants[memberId];
    }

    updateEvent(database, event);
  };

  if (!members) {
    return <Spinner />;
  }

  return (
    <div className="content">
      {!event && (
        <EventSelector
          onEventSelect={(eventId: string) => setEventId(eventId)}
        />
      )}
      {event && (
        <RegistrationForm
          onRegistrationChange={handleRegistrationChange}
          members={members}
          event={event}
          semester={semester}
          onChangeEvent={() => setEventId(undefined)}
        />
      )}
    </div>
  );
};
