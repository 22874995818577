import { Link } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { FormEvent, useState } from "react";
import { useFirebaseAuth } from "hooks";
import { sendPasswordResetEmail } from "firebase/auth";

export const PasswordForgetPage = () => (
  <div className="password-forget__container">
    <h1>YadahReg</h1>
    <h2>Glemt passord</h2>
    <PasswordForgetForm />
  </div>
);

export const PasswordForgetForm = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | undefined>();

  const auth = useFirebaseAuth();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!auth) return;
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setEmail("");
        setError(undefined);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const isInvalid = email === "";

  return (
    <form onSubmit={handleSubmit}>
      <input
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="text"
        placeholder="E-Post"
      />

      <button
        disabled={isInvalid}
        type="submit"
        className="password-forget__submit"
      >
        Tilbakestill passord
      </button>

      {error && <p>{error}</p>}
    </form>
  );
};

export const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Glemt passord?</Link>
  </p>
);
