export const LANDING = "/";
export const SIGN_IN = "/sign-in";
export const SIGN_UP = "/sign-up";
export const HOME = "/";
export const PASSWORD_FORGET = "/forgotten-password";
export const ACCOUNT = "/user-info";
export const USER_LIST = "/users";
export const ROLES = "/roles";
export const MEMBERS = "/members";
export const REGISTRATION = "/registration";
export const PAYMENT = "/payment";
export const DATA_EXPORT = "/data-export";
export const INACTIVE_MEMBERS = "/inactive-members";
export const TOP_LIST = "/top-list";
export const MAILING_LIST = "/mailing-list";
export const ATTENDANCE_OVERVIEW = "/attendance-overview";
export const ALLERGIES = "/allergies";
export const SEMESTER_STATISTICS = "/semester-statistics";
export const PLOTS = "/plots";
