export const EVENT_TYPES_WRITE = 'event-types-write';
export const EVENTS_READ = 'events-read';
export const EVENTS_WRITE = 'events-write';
export const MEMBERS_READ = 'members-read';
export const MEMBERS_WRITE = 'members-write';
export const ROLES_WRITE = 'roles-write';
export const USERS_READ = 'users-read';
export const USERS_WRITE = 'users-write';
export const SEMESTERS_READ = 'semesters-read';
export const SEMESTERS_WRITE = 'semesters-write';
export const ROOT_READ = 'root-read';
export const SET_RESTRICTED_ROLES = 'set-restricted-roles';
