import { useDatabase, useEventTypes } from "hooks";
import { FormEvent, MouseEvent, useEffect, useState } from "react";
import { IEvent } from "types";
import { createEvent, deleteEvent, updateEvent } from "utils/data";

interface IProps {
  event?: IEvent;
  onSubmit: (event: IEvent | null) => void;
}
const EventForm = ({ event: eventProp, onSubmit }: IProps) => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(new Date());
  const [type, setType] = useState("");

  const database = useDatabase();
  const eventTypes = useEventTypes();

  useEffect(() => {
    if (eventProp) {
      setTitle(eventProp.title);
      setDate(eventProp.date);
      setType(eventProp.type);
    }
  }, [eventProp]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!database) return;

    const event: IEvent = { id: eventProp?.id ?? "", title, date, type };
    if (eventProp) {
      updateEvent(database, event);
    } else {
      event.id = await createEvent(database, event);
    }

    if (onSubmit) {
      onSubmit(event);
    }
  };

  const handleDelete = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!database) return;

    if (!window.confirm("Er du sikker på at du vil slette arrangementet?")) {
      return;
    }

    if (eventProp) {
      await deleteEvent(database, eventProp.id);
    }

    if (onSubmit) {
      onSubmit(null);
    }
  };

  return (
    <form className="event-form" onSubmit={handleSubmit}>
      <h1>{eventProp ? "Rediger arrangement" : "Nytt arrangement"}</h1>
      <label htmlFor="title">Tittel</label>
      <input
        name="title"
        value={title}
        onChange={(e) => setTitle(e.currentTarget.value)}
        type="text"
      />

      <label htmlFor="type">Type</label>
      <select
        value={type}
        onChange={(e) => setType(e.currentTarget.value)}
        name="type"
      >
        <option value="" disabled>
          Velg type
        </option>
        {eventTypes?.map((eventType) => (
          <option value={eventType} key={eventType}>
            {eventType}
          </option>
        ))}
      </select>

      <label htmlFor="date">Dato</label>
      <input
        name="date"
        value={date.toISOString().split("T")[0]}
        onChange={(e) => setDate(new Date(e.currentTarget.value))}
        type="date"
      />

      <button type="submit" className="btn">
        Lagre
      </button>

      {eventProp && (
        <button
          className="btn btn-danger"
          style={{ float: "right" }}
          onClick={handleDelete}
        >
          Slett <i className="fas fa-trash-alt" />
        </button>
      )}
    </form>
  );
};

export default EventForm;
