import { Spinner } from "components";
import { Modal } from "components/Modal";
import { useUsers } from "hooks";
import { useState } from "react";
import { IUser } from "types";
import { UserForm } from "./UserForm";

export const UsersPage = () => {
  const [loading] = useState(false);
  const users = useUsers();
  const [modalActive, setModalActive] = useState(false);
  const [editUser, setEditUser] = useState<IUser | undefined>(undefined);

  return (
    <div className="content">
      <h1>Brukere</h1>

      <Modal active={modalActive} onClose={() => setModalActive(false)}>
        <UserForm
          user={editUser}
          onSubmit={() => {
            setEditUser(undefined);
            setModalActive(false);
          }}
        />
      </Modal>

      {loading && <Spinner />}

      {!loading && (
        <UsersList
          users={users}
          onEditUser={(user) => {
            setEditUser(user);
            setModalActive(true);
          }}
        />
      )}
    </div>
  );
};

interface IUsersListProps {
  users: IUser[];
  onEditUser: (user: IUser) => void;
}
const UsersList = ({ users, onEditUser }: IUsersListProps) => (
  <table className="table-full-width table-hor-lines-between">
    <thead>
      <tr>
        <th>Navn</th>
        <th className="desktop-only">E-post</th>
        <th className="desktop-only">Rolle</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {users.map((user) => (
        <tr key={user.uid}>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td>{user.role}</td>
          <td>
            <button className="btn btn-small" onClick={() => onEditUser(user)}>
              <i className="fas fa-edit" />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
