import { ChangeEvent, FormEvent, useState } from "react";
import { Spinner } from "components";
import { Modal } from "components/Modal";
import { useDatabase, usePermissions, useRoles } from "hooks";
import { IRole, IPermission } from "types";
import { updateRole } from "utils/data";

const RolesList = () => {
  const roles = useRoles();
  const permissions = usePermissions();
  const [selectedRole, setSelectedRole] = useState<IRole | undefined>();
  const [modalActive, setModalActive] = useState(false);

  return (
    <>
      <Modal
        active={modalActive}
        onClose={() => {
          setModalActive(false);
          setSelectedRole(undefined);
        }}
      >
        <RoleForm
          role={selectedRole}
          permissions={permissions}
          onSubmit={() => {
            setModalActive(false);
            setSelectedRole(undefined);
          }}
        />
      </Modal>

      <button
        className="btn"
        onClick={() => {
          setSelectedRole(undefined);
          setModalActive(true);
        }}
      >
        Ny rolle
      </button>

      {!roles.length || !permissions.length ? (
        <Spinner />
      ) : (
        <table className="table-full-width table-hor-lines-between">
          <thead>
            <tr>
              <th>Rolle</th>
              <th>Beskrivelse</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role) => (
              <tr key={role.name}>
                <td>{role.name}</td>
                <td>{role.description}</td>
                <td>
                  <button
                    className="btn btn-small"
                    onClick={() => {
                      setSelectedRole(role);
                      setModalActive(true);
                    }}
                  >
                    <i className="fas fa-edit" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

interface IRoleFormProps {
  role?: IRole;
  onSubmit?: () => void;
  permissions: IPermission[];
}
const RoleForm = ({
  role: roleProp,
  onSubmit: handleSubmitProp,
  permissions: availablePermissions,
}: IRoleFormProps) => {
  const role: IRole = {
    name: "",
    description: "",
    restricted: false,
    permissions: {},
    ...roleProp,
  };

  const [name, setName] = useState(role.name);
  const [description, setDescription] = useState(role.description);
  const [restricted, setRestricted] = useState(role.restricted);
  const [permissions, setPermissions] = useState(role.permissions);

  const database = useDatabase();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!database) return;

    const role: IRole = {
      name,
      description,
      permissions,
      restricted,
    };
    await updateRole(database, role);

    if (handleSubmitProp) {
      handleSubmitProp?.();
    }
  };

  const handlePermissionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const permissions_ = { ...permissions };
    if (e.target.checked) {
      permissions_[e.target.name] = e.target.name;
    } else {
      if (permissions_[e.target.name]) {
        delete permissions_[e.target.name];
      }
    }

    setPermissions(permissions_);
  };

  return (
    <form onSubmit={handleSubmit}>
      {roleProp && <h1>{name}</h1>}
      {!roleProp && (
        <>
          <label htmlFor="name">Navn</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </>
      )}

      <label htmlFor="description">Beskrivelse</label>
      <input
        type="text"
        name="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <label htmlFor="restricted">Begrenset</label>
      <input
        type="checkbox"
        name="restricted"
        checked={restricted}
        onChange={(e) => setRestricted(e.target.checked)}
      />
      <p className="light">
        Bare brukere med tilgangen "set-restricted-roles" kan tildele begrensede
        roller. Dette er nyttig for å la en moderator kunne dele ut alle andre
        roller enn sin egen eller høyere.
      </p>

      <h2>Tilganger</h2>
      {availablePermissions.map((permission) => (
        <span key={permission.name}>
          <label>{permission.name}</label>
          <input
            type="checkbox"
            name={permission.name}
            key={permission.name}
            checked={!!permissions && !!permissions[permission.name]}
            onChange={handlePermissionChange}
          />
        </span>
      ))}
      <button type="submit" className="btn">
        Lagre
      </button>
    </form>
  );
};

export const RolesPage = () => (
  <div className="content">
    <h1>Roller</h1>
    <RolesList />
  </div>
);
