import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import "./styles/__main.scss";
import "../node_modules/fontawesome-free/css/fontawesome.css";
import "../node_modules/fontawesome-free/css/brands.css";
import "../node_modules/fontawesome-free/css/solid.css";
import { AuthUserHandler } from "components/firebase/AuthUserHandler";
import { FirebaseAppHandler } from "components/firebase/FirebaseAppHandler";
import { FirebaseAuthHandler } from "components/firebase/FirebaseAuthHandler";
import { DatabaseHandler } from "components/firebase/DatabaseHandler";

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppHandler>
      <FirebaseAuthHandler>
        <DatabaseHandler>
          <AuthUserHandler>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthUserHandler>
        </DatabaseHandler>
      </FirebaseAuthHandler>
    </FirebaseAppHandler>
  </React.StrictMode>,
  document.getElementById("root")
);
