import { DatabaseContext } from "contexts";
import { Database, getDatabase } from "firebase/database";
import { useFirebaseApp } from "hooks";
import { ReactChild, useEffect } from "react";
import { useState } from "react";

interface IProps {
  children: ReactChild;
}
export const DatabaseHandler = ({ children }: IProps) => {
  const [database, setDatabase] = useState<Database>();
  const firebaseApp = useFirebaseApp();

  useEffect(() => {
    setDatabase(getDatabase(firebaseApp));
  }, [firebaseApp, setDatabase]);

  return (
    <DatabaseContext.Provider value={database}>
      {children}
    </DatabaseContext.Provider>
  );
};
