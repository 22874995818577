import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { IRole } from "types";
import { IRoleDbo } from "types/contractTypes";
import { useDatabase } from "./useDatabase";

export const useRoles = (): IRole[] => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const database = useDatabase();

  useEffect(() => {
    if (!database) return;

    const unsubscribe = onValue(ref(database, "roles"), (snapshot) => {
      const rolesObject = snapshot.val();
      const roles = Object.keys(rolesObject).map((key) => {
        const dbo: IRoleDbo = rolesObject[key];
        const role: IRole = {
          name: key,
          description: dbo.description,
          permissions: dbo.permissions,
          restricted: dbo.restricted,
        };
        return role;
      });
      setRoles(roles);
    });
    return unsubscribe;
  }, [database]);

  return roles;
};
