import { FirebaseAppContext } from "contexts";
import { initializeApp } from "firebase/app";
import { useState } from "react";
import { ReactChild } from "react";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

interface IProps {
  children: ReactChild;
}
export const FirebaseAppHandler = ({ children }: IProps) => {
  const [firebaseApp] = useState(initializeApp(firebaseConfig));

  return (
    <FirebaseAppContext.Provider value={firebaseApp}>
      {children}
    </FirebaseAppContext.Provider>
  );
};
