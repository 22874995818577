import { useState } from "react";
import { jStat } from "jstat";
import { useEvents, useSemesters } from "hooks";
import { ISemester } from "types";
import round from "utils/round";
import { Spinner } from "components";

export const SemesterStatistics = () => {
  const semesters = useSemesters();
  const events = useEvents();
  const [selectedSemester, setSelectedSemester] = useState<
    ISemester | undefined
  >();

  const filteredEvents = events?.filter((event) => {
    return (
      (!selectedSemester ||
        (event.date >= selectedSemester.startDate &&
          event.date <= selectedSemester.endDate)) &&
      event.type === "Øvelse"
    );
  });

  const attendance = filteredEvents
    ?.map((e) => (e.attendants ? Object.keys(e.attendants).length : 0))
    .filter((x) => x !== 0);

  const meanAttendance = attendance ? round(jStat.mean(attendance), 2) : null;
  const medianAttendance = attendance ? jStat.median(attendance) : null;
  let modeAttendance: number | number[] | string | null = attendance
    ? jStat.mode(attendance)
    : null;
  if (Array.isArray(modeAttendance)) {
    modeAttendance = modeAttendance.join(", ");
  }

  const maxAttendance = attendance ? jStat.max(attendance) : null;
  const minAttendance = attendance ? jStat.min(attendance) : null;

  return (
    <div className="content">
      <h1>Semesterstatistikk</h1>

      {(!semesters || !events) && <Spinner />}
      {!!semesters && !!events && (
        <>
          <select
            onChange={(e) =>
              setSelectedSemester(
                semesters?.find((s) => s.id === e.target.value)
              )
            }
            value={selectedSemester ? selectedSemester.id : ""}
          >
            <option value={""}>Totalt</option>
            {semesters.map((semester) => (
              <option value={semester.id} key={semester.id}>
                {semester.title}
              </option>
            ))}
          </select>

          <table className="table-full-width table-hor-lines-between">
            <tbody>
              <tr>
                <td>Antall øvelser</td>
                <td>{filteredEvents?.length}</td>
              </tr>
              <tr>
                <td>Gjennomsnittlig oppmøte</td>
                <td>{meanAttendance}</td>
              </tr>
              <tr>
                <td>Maksimalt oppmøte</td>
                <td>{maxAttendance}</td>
              </tr>
              <tr>
                <td>Minimalt oppmøte</td>
                <td>{minAttendance}</td>
              </tr>
              <tr>
                <td>Median</td>
                <td>{medianAttendance}</td>
              </tr>
              <tr>
                <td>Typetall</td>
                <td>{modeAttendance}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
