import { Spinner } from "components";
import { useEvents, useEventTypes, useMembers, useSemesters } from "hooks";
import { ChangeEvent, Fragment, useEffect, useMemo, useState } from "react";
import { IEvent, IMember, ISemester } from "types";

export const TopListPage = () => {
  const [selectedSemester, setSelectedSemester] = useState<
    ISemester | undefined
  >();
  const [eventTypeDrawerOpen, setEventTypeDrawerOpen] = useState(false);
  const [selectedEventTypes, setSelectedEventTypes] = useState(["Øvelse"]);
  const [filter, setFilter] = useState("");

  const members = useMembers(false);
  const semesters = useSemesters();
  const events = useEvents();
  const eventTypes = useEventTypes();

  useEffect(() => {
    setSelectedSemester(semesters?.[0]);
  }, [semesters]);

  const handleEventTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const eventType = event.currentTarget.name;
    let newEventTypes = [...selectedEventTypes];
    if (
      event.currentTarget.checked &&
      !selectedEventTypes.includes(eventType)
    ) {
      newEventTypes.push(eventType);
    } else if (
      !event.currentTarget.checked &&
      selectedEventTypes.includes(eventType)
    ) {
      newEventTypes = selectedEventTypes.filter((t) => t !== eventType);
    }
    setSelectedEventTypes(newEventTypes);
  };

  const startDate =
    semesters && semesters.length
      ? selectedSemester
        ? selectedSemester.startDate
        : new Date(1970, 1, 1)
      : undefined;
  const endDate =
    semesters && semesters.length
      ? selectedSemester
        ? selectedSemester.endDate
        : new Date(4000, 1, 1)
      : undefined;
  return (
    <div className="content">
      <h1>Toppliste</h1>
      <select
        onChange={(event) =>
          setSelectedSemester(
            semesters?.filter(
              (semester) => semester.id === event.target.value
            )[0]
          )
        }
        value={selectedSemester ? selectedSemester.id : ""}
      >
        <option value={undefined}>Totalt</option>
        {semesters?.map((semester) => (
          <option value={semester.id} key={semester.id}>
            {semester.title}
          </option>
        ))}
      </select>
      <div
        onClick={() => setEventTypeDrawerOpen(!eventTypeDrawerOpen)}
        style={{ cursor: "pointer", padding: "15px" }}
      >
        Typer arrangementer{" "}
        {eventTypeDrawerOpen ? (
          <i className="fas fa-caret-down" />
        ) : (
          <i className="fas fa-caret-right" />
        )}
      </div>
      {eventTypeDrawerOpen && (
        <div style={{ padding: "0 15px" }}>
          {eventTypes?.map((eventType) => (
            <Fragment key={eventType}>
              <input
                type="checkbox"
                checked={selectedEventTypes.includes(eventType)}
                name={eventType}
                id={eventType}
                onChange={handleEventTypeChange}
                className="inline"
              />
              <label style={{ display: "inline" }} htmlFor={eventType}>
                {eventType}
              </label>
            </Fragment>
          ))}
        </div>
      )}
      <input
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        name="filter"
        type="text"
        placeholder="Søk..."
      />
      <TopList
        members={members}
        events={events}
        eventTypes={selectedEventTypes}
        startDate={startDate}
        endDate={endDate}
        filter={filter}
      />
    </div>
  );
};

interface ITopListMember extends IMember {
  eventCount: number;
  eventFraction: number;
  placement: number;
}

interface ITopListProps {
  members?: IMember[];
  events?: IEvent[];
  startDate?: Date;
  endDate?: Date;
  eventTypes: string[];
  filter: string;
}
const TopList = ({
  members,
  events,
  startDate,
  endDate,
  eventTypes = ["Øvelse"],
  filter,
}: ITopListProps) => {
  if (startDate && endDate && events) {
    events = events.filter((event) => {
      const d = event.date;
      return (
        d >= startDate &&
        d <= endDate &&
        (!eventTypes || eventTypes.includes(event.type))
      );
    });
  }

  const membersWithAttendance = useMemo(() => {
    if (!members || !events || !startDate || !endDate) return [];

    let result = members as ITopListMember[];

    result = result.map((member) => {
      const possibleEvents = events!.filter((e) => {
        const memberJoinDate = new Date(member.createdAt);
        memberJoinDate.setUTCHours(0, 0, 0, 0);
        return !member.createdAt || e.date >= memberJoinDate;
      }).length;
      const count = events!.reduce((prev, cur) => {
        if (cur.attendants && cur.attendants[member.id]) {
          return prev + 1;
        }
        return prev;
      }, 0);

      return {
        ...member,
        eventCount: count,
        eventFraction: possibleEvents ? count / possibleEvents : 0,
      };
    });

    result = result
      .filter((m) => m.eventCount > 0)
      .sort((a, b) => b.eventCount - a.eventCount);
    let currentPlacement = 0;
    let placement = 0;
    let lastCount = 100000000;
    result = result.map((member) => {
      placement += 1;
      if (member.eventCount < lastCount) {
        currentPlacement = placement;
        lastCount = member.eventCount;
      }

      return { ...member, placement: currentPlacement };
    });
    return result;
  }, [events, members, startDate, endDate]);

  if (!members || !events || !startDate || !endDate) {
    return <Spinner />;
  }

  const filteredMembers = membersWithAttendance.filter((m) => {
    const regex = new RegExp("(.*)" + filter + "(.*)", "i");
    return regex.test(`${m.firstName} ${m.lastName}`);
  });

  return (
    <table className="table-full-width table-hor-lines-between">
      <thead>
        <tr>
          <th>Plass</th>
          <th>Navn</th>
          <th>Antall</th>
          <th title="Estimert andel av arrangementer som medlemmet har vært med i valgt periode som var etter at vedkommende ble med i koret.">
            Andel
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredMembers.map((member) => (
          <tr key={member.id}>
            <td>{member.placement}</td>
            <td>
              {member.firstName} {member.lastName}
            </td>
            <td>{member.eventCount}</td>
            <td title="Estimert andel av arrangementer som medlemmet har vært med i valgt periode som var etter at vedkommende ble med i koret.">
              {(member.eventFraction * 100).toFixed(2)}%
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
