import { IButtonSelectOption, PaymentStatus, RegistrationStatus } from "types";

interface IProps {
  options: IButtonSelectOption[];
  value: RegistrationStatus | PaymentStatus;
  onChange: (event: { value: RegistrationStatus | PaymentStatus }) => void;
}
export const ButtonSelect = ({
  value = "not-present",
  onChange,
  options,
}: IProps) => (
  <span className="button-select">
    {options &&
      options.map((option) => (
        <button
          className={`
                            btn btn-small 
                            ${option["className"] ? option["className"] : ""}
                            ${value === option.value ? "selected" : ""} 
                        `}
          onClick={() => onChange({ value: option.value })}
          title={option.tooltip ? option.tooltip : ""}
          key={option.value}
        >
          {option.text}
        </button>
      ))}
  </span>
);
