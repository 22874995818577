import { useMembers } from "hooks";
import { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { DataType } from "./Plots";
import { generateNetwork } from "./scripts/generateNetwork";

const INITIAL_SIZE = 600;

const useStyles = createUseStyles({
  container: { width: "100%", height: "100%" },
  svg: { width: "100%", height: "100%" },
});

interface IProps {
  dataType: DataType;
}
export const Network = ({ dataType }: IProps) => {
  const members = useMembers();
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!members || !svgRef.current) return;
    return generateNetwork(members, svgRef);
  }, [members, svgRef]);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        className={classes.svg}
        ref={svgRef}
        viewBox={`-${INITIAL_SIZE / 2} -${
          INITIAL_SIZE / 2
        } ${INITIAL_SIZE} ${INITIAL_SIZE}`}
        preserveAspectRatio="xMidYMid meet"
      />
    </div>
  );
};
