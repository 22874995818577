import { SEMESTERS_READ } from "constants/permissions";
import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { ISemester } from "types";
import { ISemesterDbo } from "types/contractTypes";
import { useAuthUser } from "./useAuthUser";
import { useDatabase } from "./useDatabase";

export const useSemesters = () => {
  const [semesters, setSemesters] = useState<ISemester[] | undefined>();
  const database = useDatabase();
  const [authUser] = useAuthUser();

  useEffect(() => {
    if (!database || !authUser) return;
    if (!authUser.permissions[SEMESTERS_READ]) return;

    const unsubscribe = onValue(ref(database, "semesters"), (snapshot) => {
      const semestersObject = snapshot.val();
      const semesterList: ISemester[] = Object.keys(semestersObject).map(
        (key) => {
          const dbo: ISemesterDbo = semestersObject[key];
          return mapSemester(key, dbo);
        }
      );
      setSemesters(
        semesterList.sort((a, b) => b.endDate.getTime() - a.endDate.getTime())
      );
    });

    return unsubscribe;
  }, [database, authUser]);

  return semesters;
};

export const mapSemester = (semesterId: string, semesterDbo: ISemesterDbo) => ({
  id: semesterId,
  title: semesterDbo.title,
  startDate: new Date(semesterDbo.start_date),
  endDate: new Date(semesterDbo.end_date),
  payees: semesterDbo.payees ?? {},
});
