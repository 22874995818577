import { Spinner } from "components";
import { useMembers } from "hooks";
import { useState } from "react";
import { IMember } from "types";
import { copyTextToClipboard } from "utils/copyTextToClipboard";

export const MailingList = () => {
  const members = useMembers();
  const [useDates, setUseDates] = useState(false);

  const defaultStartDate = new Date();
  defaultStartDate.setUTCHours(0, 0, 0, 0);
  defaultStartDate.setUTCDate(defaultStartDate.getUTCDate() - 6);
  const [startDate, setStartDate] = useState(defaultStartDate);

  const defaultEndDate = new Date();
  defaultEndDate.setUTCHours(0, 0, 0, 0);
  defaultEndDate.setUTCDate(defaultEndDate.getUTCDate() + 1);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const chosenMembers = useDates
    ? members
        ?.filter((member: IMember) => !!member.createdAt)
        .filter(
          (member: IMember) =>
            member.createdAt > startDate && member.createdAt < endDate
        )
    : members;
  const mails = chosenMembers?.map((member: IMember) => member.email);
  const mailString = mails?.join(";");

  return (
    <div className="content">
      {!members && <Spinner />}
      {!!members && (
        <>
          <label htmlFor="useDates">Bare send til nye medlemmer</label>
          <input
            type="checkbox"
            name="useDates"
            checked={useDates}
            onChange={(e) => setUseDates(e.currentTarget.checked)}
          />
          {useDates && (
            <>
              <label htmlFor="startDate">Startdato:</label>
              <input
                type="date"
                name="startDate"
                value={startDate.toISOString().split("T")[0]}
                onChange={(e) => setStartDate(new Date(e.target.value))}
              />

              <label htmlFor="endDate">Sluttdato:</label>
              <input
                type="date"
                name="endDate"
                value={endDate.toISOString().split("T")[0]}
                onChange={(e) => setEndDate(new Date(e.target.value))}
              />
            </>
          )}
          <a href={"mailto:" + mailString} className="btn">
            Send mail
          </a>
          <button
            className="btn"
            onClick={() => {
              if (!!mailString) {
                copyTextToClipboard(mailString);
              }
            }}
          >
            Kopier mailadresser
          </button>
        </>
      )}
    </div>
  );
};
