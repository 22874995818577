import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { IPermission } from "types";
import { IPermissionDbo } from "types/contractTypes";
import { useDatabase } from "./useDatabase";

export const usePermissions = (): IPermission[] => {
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const database = useDatabase();

  useEffect(() => {
    if (!database) return;

    const unsubscribe = onValue(ref(database, "permissions"), (snapshot) => {
      const permissionsObject = snapshot.val();
      const permissions = Object.keys(permissionsObject).map((key) => {
        const dbo: IPermissionDbo = permissionsObject[key];
        const permission: IPermission = {
          name: key,
          description: dbo.description,
        };
        return permission;
      });
      setPermissions(permissions);
    });
    return unsubscribe;
  }, [database]);

  return permissions;
};
