import { Spinner } from "components";
import {
  AuthProvider,
  EmailAuthProvider,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  linkWithCredential,
  linkWithPopup,
  unlink,
} from "firebase/auth";
import { useFirebaseAuth, useAuthUser } from "hooks";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { PasswordChangeForm } from "./PasswordChange";

interface ISignInMethod {
  id: string;
  title: string;
  provider: AuthProvider | null;
}
const SIGN_IN_METHODS: ISignInMethod[] = [
  {
    id: "password",
    title: "Passord",
    provider: null,
  },
  {
    id: "google.com",
    title: "Google",
    provider: new GoogleAuthProvider(),
  },
  {
    id: "facebook.com",
    title: "Facebook",
    provider: new FacebookAuthProvider(),
  },
];

export const Account = () => {
  const [authUser] = useAuthUser();

  if (!authUser) {
    return <Spinner />;
  }

  return (
    <div className="content">
      <h1>{authUser.name}</h1>
      <p>
        <strong>E-post:</strong> {authUser?.email}
      </p>
      <PasswordChangeForm />
      <LoginManagement />
    </div>
  );
};
const LoginManagement = () => {
  const [activeSignInMethods, setActiveSignInMethods] = useState<string[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [authUser] = useAuthUser();
  const auth = useFirebaseAuth();

  const fetchSignInMethods = useCallback(() => {
    if (!auth || !authUser?.email) return;

    fetchSignInMethodsForEmail(auth, authUser.email)
      .then((activeSignInMethods) => {
        setActiveSignInMethods(activeSignInMethods);
        setError(undefined);
      })
      .catch((error) => setError(error.message));
  }, [auth, authUser]);

  useEffect(() => {
    fetchSignInMethods();
  }, [fetchSignInMethods]);

  const onSocialLoginLink = (provider: AuthProvider) => {
    if (!auth?.currentUser) return;

    linkWithPopup(auth.currentUser, provider)
      .then(fetchSignInMethods)
      .catch((error) => setError(error.message));
  };

  const onUnlink = (providerId: string) => {
    if (!auth?.currentUser) return;
    unlink(auth.currentUser, providerId)
      .then(fetchSignInMethods)
      .catch((error) => setError(error.message));
  };

  const onDefaultLoginLink = (password: string) => {
    if (!authUser?.email || !auth?.currentUser) return;

    const credential = EmailAuthProvider.credential(authUser.email, password);
    linkWithCredential(auth.currentUser, credential)
      .then(fetchSignInMethods)
      .catch((error) => setError(error.message));
  };

  return (
    <div>
      <br />
      <h1>Innloggingsmetoder:</h1>
      <ul>
        {SIGN_IN_METHODS.map((signInMethod) => {
          const onlyOneLeft = activeSignInMethods.length === 1;
          const isEnabled = activeSignInMethods.includes(signInMethod.id);

          return (
            <li key={signInMethod.id}>
              {signInMethod.id === "password" ? (
                <DefaultLoginToggle
                  onlyOneLeft={onlyOneLeft}
                  isEnabled={isEnabled}
                  signInMethod={signInMethod}
                  onLink={onDefaultLoginLink}
                  onUnlink={onUnlink}
                />
              ) : (
                <SocialLoginToggle
                  onlyOneLeft={onlyOneLeft}
                  isEnabled={isEnabled}
                  signInMethod={signInMethod}
                  onLink={onSocialLoginLink}
                  onUnlink={onUnlink}
                />
              )}
            </li>
          );
        })}
      </ul>
      {!!error && error}
    </div>
  );
};

interface ISocialLoginToggleProps {
  onlyOneLeft: boolean;
  isEnabled: boolean;
  signInMethod: ISignInMethod;
  onLink: (provider: AuthProvider) => void;
  onUnlink: (providerId: string) => void;
}
const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}: ISocialLoginToggleProps) =>
  isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.provider!.providerId)}
      disabled={onlyOneLeft}
      className="btn"
    >
      Deaktiver {signInMethod.title}
    </button>
  ) : (
    <button
      type="button"
      onClick={() => onLink(signInMethod.provider!)}
      className="btn"
    >
      Link {signInMethod.title}
    </button>
  );

interface IDefaultLoginToggleProps {
  onLink: (password: string) => void;
  onUnlink: (providerId: string) => void;
  onlyOneLeft: boolean;
  signInMethod: ISignInMethod;
  isEnabled: boolean;
}
const DefaultLoginToggle = ({
  onLink,
  onUnlink,
  onlyOneLeft,
  signInMethod,
  isEnabled,
}: IDefaultLoginToggleProps) => {
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    onLink(passwordOne);
    setPasswordOne("");
    setPasswordTwo("");
  };

  const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

  return isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
      className="btn"
    >
      Deaktiver {signInMethod.title}
    </button>
  ) : (
    <form onSubmit={handleSubmit}>
      <input
        name="passwordOne"
        value={passwordOne}
        onChange={(e) => setPasswordOne(e.target.value)}
        type="password"
        placeholder="Nytt passord"
      />

      <input
        name="passwordTwo"
        value={passwordTwo}
        onChange={(e) => setPasswordTwo(e.target.value)}
        type="password"
        placeholder="Bekreft nytt passord"
      />

      <button disabled={isInvalid} type="submit" className="btn">
        Link {signInMethod.title}
      </button>
    </form>
  );
};
