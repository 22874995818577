import { useMembers } from "hooks";
import { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { DataType } from "./Plots";
import { generateBar } from "./scripts/generateBar";

const useStyles = createUseStyles({
  container: { width: "100%", height: "100%" },
  svg: { width: "100%", height: "100%" },
});

const INITIAL_SIZE = 100;

interface IProps {
  dataType: DataType;
}
export const Bar = ({ dataType }: IProps) => {
  const members = useMembers();
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!members || !svgRef.current) return;
    return generateBar(members, svgRef, INITIAL_SIZE);
  }, [members, svgRef]);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <svg
        className={classes.svg}
        ref={svgRef}
        viewBox={`${-INITIAL_SIZE / 4} ${-INITIAL_SIZE / 4} ${
          INITIAL_SIZE * 2
        } ${INITIAL_SIZE * 2}`}
        preserveAspectRatio="xMidYMid meet"
      />
    </div>
  );
};
