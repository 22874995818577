import React, { KeyboardEvent, useState } from "react";
import { ButtonSelect } from "components";
import {
  IEvent,
  IMember,
  IButtonSelectOption,
  ISemester,
  RegistrationStatus,
} from "types";
import { formatDate, hasBirthday } from "utils/dates";
import { MemberForm } from "../Members";
import { Modal } from "components/Modal";

const presenceOptions: IButtonSelectOption[] = [
  {
    value: "present",
    text: <i className="fas fa-check-circle" />,
    tooltip: "Til stede",
  },
  {
    value: "notified",
    text: <i className="fas fa-comment" />,
    tooltip: "Gitt beskjed",
  },
  {
    value: "not-present",
    text: <i className="fas fa-times-circle" />,
    tooltip: "Ikke til stede",
  },
];

interface IRegistrationFormProps {
  members: IMember[];
  onRegistrationChange: (memberId: string, value: RegistrationStatus) => void;
  event: IEvent;
  semester?: ISemester;
  onChangeEvent: () => void;
}
export const RegistrationForm = ({
  members,
  onRegistrationChange,
  event,
  semester,
  onChangeEvent,
}: IRegistrationFormProps) => {
  const [filter, setFilter] = useState("");
  const [memberModalActive, setMemberModalActive] = useState(false);

  const getStatus = (member: IMember) => {
    if (event) {
      if (event.attendants && !!event.attendants[member.id]) {
        return "present";
      }
      if (event.nonAttendants && !!event.nonAttendants[member.id]) {
        return "notified";
      }
    }
    return "not-present";
  };

  const isMatch = (filter: string, name: string) => {
    const regex = new RegExp("(.*)" + filter + "(.*)", "i");
    return regex.test(name);
  };

  const handleFilterKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const matchingMembers = members.filter((member) => {
        const res = isMatch(filter, member.firstName + " " + member.lastName);
        return res;
      });
      if (matchingMembers.length === 1) {
        onRegistrationChange(matchingMembers[0].id, "present");

        setTimeout(() => {
          setFilter("");
        }, 600);
      }
    }
  };

  const handleAddMember = (memberId: string) => {
    onRegistrationChange(memberId, "present");
    setMemberModalActive(false);
  };

  return (
    <div className="registration-form">
      <Modal
        onClose={() => setMemberModalActive(false)}
        active={memberModalActive}
      >
        <MemberForm onSubmit={handleAddMember} event={event} />
      </Modal>
      <div className="registration-form__header">
        <h1>
          {formatDate(event.date)} - {event.title}
        </h1>

        <button
          className="btn btn-link registration-form__change-event"
          onClick={onChangeEvent}
        >
          Endre arrangement
        </button>
      </div>
      <div className="registration-form__util-bar">
        <div>
          <button onClick={() => setMemberModalActive(true)} className="btn">
            Nytt medlem
          </button>
        </div>

        <div className="registration-form__num-attendants">
          Antall oppmøtte:{" "}
          {event.attendants ? Object.keys(event.attendants).length : 0}
        </div>
      </div>

      <input
        value={filter}
        onChange={(e) => setFilter(e.currentTarget.value)}
        name="filter"
        type="text"
        placeholder="Søk..."
        onKeyDown={handleFilterKeyDown}
      />

      <table className="registration-form__member-table table-full-width table-hor-lines-between table-last-td-right">
        <tbody>
          {members.map((member) => (
            <React.Fragment key={member.id}>
              {(!filter ||
                isMatch(filter, member.firstName + " " + member.lastName)) && (
                <tr
                  className={
                    hasBirthday(member)
                      ? "registration-form__member--birthday"
                      : ""
                  }
                >
                  <td className="registration-form__member-name">
                    {member.firstName} {member.lastName}
                    {semester &&
                      semester.payees &&
                      !semester.payees[member.id] &&
                      "*"}
                  </td>
                  <td className="registration-form__buttons">
                    {
                      <ButtonSelect
                        options={presenceOptions}
                        onChange={(e) =>
                          onRegistrationChange(
                            member.id,
                            e.value as RegistrationStatus
                          )
                        }
                        value={getStatus(member)}
                      />
                    }
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {semester && "* Har ikke betalt siste semesteravgift"}
    </div>
  );
};

export default RegistrationForm;
