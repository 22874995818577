import React, { FormEvent, useEffect, useState } from "react";
import {
  Navigate,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";

import * as ROUTES from "../constants/routes";
import { Spinner } from "components/Spinner";
import { useFirebaseAuth } from "hooks/useFirebaseAuth";
import { getRedirectResult, signInWithEmailAndPassword } from "firebase/auth";
import { doSignInWithFacebook, doSignInWithGoogle } from "utils/auth";
import { useAuthUser } from "hooks";
import { SignUpLink } from "./auth/SignUp";

const ERROR_CODE_ACCOUNT_EXISTS =
  "auth/account-exists-with-different-credential";
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const getLoginRedirectUrl = (): string => {
  if ("redirect_after_login" in window.localStorage) {
    const redirectUrl = window.localStorage["redirect_after_login"];
    window.localStorage.removeItem("redirect_after_login");
    return redirectUrl;
  } else {
    return ROUTES.HOME;
  }
};

const handleSuccessfulLogin = (
  setError: (error: string | undefined) => void,
  navigate: NavigateFunction
) => {
  setError(undefined);
  navigate(getLoginRedirectUrl());
};

export const SignIn = () => {
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useFirebaseAuth();

  const [user, userLoaded] = useAuthUser();

  const locationState = location.state as any;
  if (!!locationState && "from" in locationState) {
    window.localStorage.setItem(
      "redirect_after_login",
      locationState.from.pathname + locationState.from.search
    );
  }

  useEffect(() => {
    if (!auth || !userLoaded || !!user) {
      return;
    }

    if (window.localStorage.hasOwnProperty("login_redirect")) {
      const loginRedirect = JSON.parse(window.localStorage["login_redirect"]);
      if (new Date() < new Date(loginRedirect["timeout"])) {
        setLoggingIn(true);
      }

      getRedirectResult(auth)
        .then(() => {
          navigate(ROUTES.HOME);
          setLoggingIn(false);
        })
        .catch((error: string) => {
          setLoggingIn(false);
          setError(error);
        });
      window.localStorage.removeItem("login_redirect");
    }
  }, [auth, navigate, location, user, userLoaded]);

  if (!!user) {
    return <Navigate to={getLoginRedirectUrl()} replace />;
  }

  if (!auth || !userLoaded) {
    return <Spinner />;
  }

  return (
    <div className="signin__container">
      <h1>YadahReg</h1>
      <h2>{loggingIn ? "Logger inn..." : "Logg inn"}</h2>
      {error && <p>{error}</p>}
      {loggingIn && <Spinner />}
      {!loggingIn && (
        <React.Fragment>
          <SignInForm />
          <SignInGoogle />
          <SignInFacebook />
          <SignUpLink />
        </React.Fragment>
      )}
    </div>
  );
};

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);

  const navigate = useNavigate();
  const auth = useFirebaseAuth();

  const isInvalid = password === "" || email === "";

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!auth) {
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setEmail("");
        setPassword("");
        handleSuccessfulLogin(setError, navigate);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="text"
        placeholder="E-post"
      />
      <input
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
        placeholder="Passord"
      />

      {/* <PasswordForgetLink /> */}

      <button disabled={isInvalid} type="submit" className="signin__submit">
        Logg inn
      </button>

      {error && <p>{error}</p>}
    </form>
  );
};

export const SignInGoogle = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const auth = useFirebaseAuth();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!auth) {
      return;
    }

    doSignInWithGoogle(auth)
      .then(() => {
        handleSuccessfulLogin(setError, navigate);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        setError(error.message);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <button type="submit" className="signin__social signin__social-google">
        Logg inn med Google
      </button>

      {error && <p>{error}</p>}
    </form>
  );
};

export const SignInFacebook = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const auth = useFirebaseAuth();
  const navigate = useNavigate();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!auth) {
      return;
    }

    doSignInWithFacebook(auth)
      .then(() => {
        handleSuccessfulLogin(setError, navigate);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        setError(error.message);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <button type="submit" className="signin__social signin__social-facebook">
        Logg inn med Facebook
      </button>
      {error && <p>{error}</p>}
    </form>
  );
};
