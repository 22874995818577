import { onValue, ref } from "firebase/database";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  useAuthUser,
  useDatabase,
  useEvents,
  useMembers,
  useSemesters,
} from "hooks";
import { formatDate } from "utils/dates";
import * as PERMISSIONS from "constants/permissions";

export const DataExport = () => {
  const [authUser] = useAuthUser();

  const members = useMembers();
  const events = useEvents();
  const semesters = useSemesters();
  const database = useDatabase();

  const exportAllAsExcel = () => {
    if (!members || !events || !semesters) return;

    const membersSheet = XLSX.utils.json_to_sheet(members, {
      header: [
        "uid",
        "last_name",
        "first_name",
        "phone",
        "email",
        "address",
        "birthday",
        "gender",
        "active",
        "allergies",
        "voice_group",
      ],
    });

    const presence = members.map((member) => {
      const row: { [key: string]: string } = {
        last_name: member.lastName,
        first_name: member.firstName,
        uid: member.id,
      };

      events.forEach((event) => {
        let status = "";
        if (event.attendants && event.attendants[member.id]) {
          status = "1";
        } else if (event.nonAttendants && event.nonAttendants[member.id]) {
          status = "0";
        }
        row[`${formatDate(event.date)} - ${event.title}`] = status;
      });

      return row;
    });

    const presenceSheet = XLSX.utils.json_to_sheet(presence, {
      header: ["uid", "last_name", "first_name"].concat(
        events.map((event) => `${formatDate(event.date)} - ${event.title}`)
      ),
    });

    const saveEvents = events.map((event) => {
      let e = { ...event };
      delete e["attendants"];
      delete e["nonAttendants"];

      return e;
    });

    const eventsSheet = XLSX.utils.json_to_sheet(saveEvents, {
      header: ["uid", "date", "title", "type"],
    });

    const payment = members.map((member) => {
      const row: { [key: string]: string } = {
        last_name: member.lastName,
        first_name: member.firstName,
        uid: member.id,
      };

      semesters.forEach((semester) => {
        row[semester.title] =
          semester.payees && semester.payees[member.id] ? "1" : "";
      });

      return row;
    });

    const paymentSheet = XLSX.utils.json_to_sheet(payment, {
      header: ["uid", "last_name", "first_name"].concat(
        semesters.map((semester) => semester.title)
      ),
    });

    const saveSemesters = semesters.map((semester) => {
      let s: any = { ...semester };
      delete s["payees"];

      return s;
    });
    const semestersSheet = XLSX.utils.json_to_sheet(saveSemesters, {
      header: ["uid", "title", "start_date", "end_date"],
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, membersSheet, "Medlemmer");
    XLSX.utils.book_append_sheet(wb, presenceSheet, "Oppmøte");
    XLSX.utils.book_append_sheet(wb, paymentSheet, "Betaling");
    XLSX.utils.book_append_sheet(wb, eventsSheet, "Arrangementer");
    XLSX.utils.book_append_sheet(wb, semestersSheet, "Semestere");
    XLSX.writeFile(wb, "yadahreg-data.xlsx", {
      bookType: "xlsx",
    });
  };

  const exportDatabaseAsJson = async () => {
    if (!database) return;

    const unsubscribe = onValue(ref(database, "/"), (snapshot) => {
      unsubscribe();

      const data = snapshot.val();

      const blob = new Blob([JSON.stringify(data, null, 4)], {
        type: "application/json",
      });

      saveAs(blob, "yadahreg-database.json");
    });
  };

  return (
    <div className="content">
      <button className="btn" onClick={exportAllAsExcel}>
        Eksporter alt som Excel
      </button>

      {!!authUser?.permissions[PERMISSIONS.ROOT_READ] && (
        <button className="btn" onClick={exportDatabaseAsJson}>
          Eksporter database som JSON
        </button>
      )}
    </div>
  );
};
