import { useDatabase } from "hooks";
import { FormEvent, useEffect, useState } from "react";
import { ISemester } from "types";
import { createSemester, updateSemester } from "utils/data";

interface IProps {
  semester?: ISemester;
  onSubmit: (semester: ISemester) => void;
}
export const SemesterForm = ({ semester: semesterProp, onSubmit }: IProps) => {
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const database = useDatabase();

  useEffect(() => {
    if (semesterProp) {
      setTitle(semesterProp.title);
      setStartDate(semesterProp.startDate);
      setEndDate(semesterProp.endDate);
    } else {
      setTitle("");
      setStartDate(new Date());
      setEndDate(new Date());
    }
  }, [semesterProp]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!database) return;

    const semester: ISemester = {
      id: semesterProp ? semesterProp.id : "",
      title,
      startDate,
      endDate,
      payees: {},
    };

    if (semesterProp) {
      updateSemester(database, semester);
    } else {
      semester.id = await createSemester(database, semester);
    }

    if (onSubmit) {
      onSubmit(semester);
    }
  };

  return (
    <form className="semester-form" onSubmit={handleSubmit}>
      <h1>{semesterProp ? "Rediger semester" : "Nytt semester"}</h1>

      <label htmlFor="title">Tittel</label>
      <input
        name="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        type="text"
      />

      <label htmlFor="start_date">Startdato</label>
      <input
        name="start_date"
        value={startDate.toISOString().split("T")[0]}
        onChange={(e) => setStartDate(new Date(e.target.value))}
        type="date"
      />

      <label htmlFor="end_date">Sluttdato</label>
      <input
        name="end_date"
        value={endDate.toISOString().split("T")[0]}
        onChange={(e) => setEndDate(new Date(e.target.value))}
        type="date"
      />

      <button type="submit" className="btn">
        Lagre
      </button>
    </form>
  );
};
