import {
  Auth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { isMobile } from "mobile-device-detect";

export const doSignInWithGoogle = (auth: Auth) => {
  if (isMobile) {
    const timeout = new Date();
    timeout.setMinutes(timeout.getMinutes() + 1);
    window.localStorage.setItem(
      "login_redirect",
      JSON.stringify({
        timeout: timeout,
      })
    );
    return signInWithRedirect(auth, new GoogleAuthProvider());
  }
  return signInWithPopup(auth, new GoogleAuthProvider());
};

export const doSignInWithFacebook = (auth: Auth) => {
  if (isMobile) {
    const timeout = new Date();
    timeout.setMinutes(timeout.getMinutes() + 1);
    window.localStorage.setItem(
      "login_redirect",
      JSON.stringify({
        timeout: timeout,
      })
    );
    return signInWithRedirect(auth, new FacebookAuthProvider());
  }
  return signInWithPopup(auth, new FacebookAuthProvider());
};
