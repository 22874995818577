import { useEffect, useState } from "react";
import { IUserSettings } from "types";
import { IUserSettingsDbo } from "types/contractTypes";

const DEFAULT_SETTINGS = {
  darkMode: false,
};

const LocalStorageKey = "yadahreg.settings";

export const useUserSettings = (): IUserSettings => {
  const [settings, setSettings] = useState<IUserSettings>({
    ...DEFAULT_SETTINGS,
  });

  useEffect(() => {
    const settingsFromLocalStorage = localStorage.getItem(LocalStorageKey);
    if (settingsFromLocalStorage) {
      const settingsDbo = JSON.parse(
        settingsFromLocalStorage
      ) as IUserSettingsDbo;
      setSettings({
        ...DEFAULT_SETTINGS,
        ...settingsDbo,
      } as IUserSettings);
    }
  }, [setSettings]);

  return settings;
};
