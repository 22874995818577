import { PhoneBilliard, Spinner } from "components";
import { Modal } from "components/Modal";
import { useDatabase, useMembers } from "hooks";
import { FormEvent, Fragment, useEffect, useState } from "react";
import { IEvent, IMember } from "types";
import { createMember, deleteMember, updateMember } from "utils/data";
import { formatDate } from "utils/dates";

export const MembersPage = () => {
  const [editMember, setEditMember] = useState<IMember | undefined>();
  const [modalActive, setModalActive] = useState(false);
  const [filter, setFilter] = useState("");
  const [showOnlyActiveMembers, setShowOnlyActiveMembers] = useState(true);
  const members = useMembers(showOnlyActiveMembers);

  const database = useDatabase();

  const handleDeleteMember = async (member: IMember) => {
    if (!database) return;
    if (
      window.confirm(
        `Er du sikker på at du vil slette ${member.firstName} ${member.lastName}?`
      )
    ) {
      await deleteMember(database, member.id);
    }
  };

  return (
    <div className="content">
      <h1>Medlemmer</h1>
      <Modal active={modalActive} onClose={() => setModalActive(false)}>
        <MemberForm
          member={editMember}
          onSubmit={() => setModalActive(false)}
        />
      </Modal>

      <button
        className="btn"
        onClick={() => {
          setEditMember(undefined);
          setModalActive(true);
        }}
      >
        Nytt medlem
      </button>
      <button
        className="btn"
        onClick={() => setShowOnlyActiveMembers(!showOnlyActiveMembers)}
      >
        {showOnlyActiveMembers
          ? "Vis inaktive medlemmer"
          : "Vis bare aktive medlemmer"}
      </button>
      {!members && <Spinner />}
      {!!members && (
        <>
          <input
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            name="filter"
            type="text"
            placeholder="Søk..."
            style={{ marginTop: "25px" }}
          />

          <MembersList
            members={members}
            onEditMember={(member) => {
              setEditMember(member);
              setModalActive(true);
            }}
            onDeleteMember={handleDeleteMember}
            filter={filter}
            showOnlyActiveMembers={showOnlyActiveMembers}
          />
        </>
      )}
    </div>
  );
};

interface IMembersListProps {
  members: IMember[];
  onEditMember: (member: IMember) => void;
  onDeleteMember: (member: IMember) => void;
  filter: string;
  showOnlyActiveMembers: boolean;
}
const MembersList = ({
  members,
  onEditMember,
  onDeleteMember,
  filter,
  showOnlyActiveMembers,
}: IMembersListProps) => {
  const isMatch = (filter: string, name: string) => {
    const regex = new RegExp("(.*)" + filter + "(.*)", "i");
    return regex.test(name);
  };

  return (
    <table className="table-full-width table-hor-lines-between table-last-td-right">
      <thead>
        <tr>
          <th>Navn</th>
          <th className="desktop-only">E-post</th>
          <th className="desktop-only">Telefon</th>
        </tr>
      </thead>
      <tbody>
        {members.map((member: IMember) => (
          <Fragment key={member.id}>
            {(!filter ||
              isMatch(filter, member.firstName + " " + member.lastName)) &&
              (!showOnlyActiveMembers || !!member.active) && (
                <tr>
                  <td>
                    {member.firstName} {member.lastName}
                  </td>
                  <td className="desktop-only">{member.email}</td>
                  <td className="desktop-only">{member.phone}</td>
                  <td>
                    <button
                      className="btn btn-small"
                      onClick={() => onEditMember(member)}
                    >
                      <i className="fas fa-edit" />
                    </button>
                    <button
                      className="btn btn-small btn-danger"
                      onClick={() => onDeleteMember(member)}
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                  </td>
                </tr>
              )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

interface IMemberFormProps {
  member?: IMember;
  onSubmit: (memberId: string) => void;
  event?: IEvent;
}
export const MemberForm = ({
  member: memberProp,
  onSubmit,
  event,
}: IMemberFormProps) => {
  const [active, setActive] = useState(true);
  const [address, setAddress] = useState("");
  const [allergies, setAllergies] = useState("");
  const [birthday, setBirthday] = useState(new Date(1995, 1, 1));
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [gender, setGender] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [voiceGroup, setVoiceGroup] = useState("");
  const [gameModalActive, setGameModalActive] = useState(false);

  const database = useDatabase();

  useEffect(() => {
    if (memberProp) {
      setActive(memberProp.active);
      setAddress(memberProp.address);
      setAllergies(memberProp.allergies);
      setBirthday(memberProp.birthday);
      setEmail(memberProp.email);
      setFirstName(memberProp.firstName);
      setGender(memberProp.gender);
      setLastName(memberProp.lastName);
      setPhone(memberProp.phone);
      setVoiceGroup(memberProp.voiceGroup);
    } else {
      setActive(true);
      setAddress("");
      setAllergies("");
      setBirthday(new Date(2002, 1, 1));
      setEmail("");
      setFirstName("");
      setGender("");
      setLastName("");
      setPhone("");
      setVoiceGroup("");
    }
  }, [memberProp]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!database) return;

    let memberId: string;
    let member: IMember = {
      id: memberProp ? memberProp.id : "",
      createdAt: memberProp ? memberProp.createdAt : new Date(),
      active,
      address,
      allergies,
      birthday,
      email,
      firstName,
      gender,
      lastName,
      phone,
      voiceGroup,
    };

    if (memberProp) {
      updateMember(database, member);
      memberId = member.id;
    } else {
      memberId = await createMember(database, member);
    }
    if (onSubmit) {
      onSubmit(memberId);
    }
  };

  return (
    <>
      <Modal
        active={gameModalActive}
        onClose={() => setGameModalActive(false)}
        contentStyle={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PhoneBilliard
          updatePhoneNumber={(phoneNumber: string) => setPhone(phoneNumber)}
        />
      </Modal>

      <form onSubmit={handleSubmit}>
        <h1>{!!memberProp ? "Rediger medlem" : "Nytt medlem"}</h1>

        {!!event && (
          <p>
            <i className="fas fa-exclamation-circle" />
            {"  "}
            Medlemmet vil automatisk bli registrert som til stede på{" "}
            {event.title} {formatDate(event.date)}
          </p>
        )}

        <label htmlFor="first_name">Fornavn</label>
        <input
          name="first_name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          type="text"
        />

        <label htmlFor="last_name">Etternavn</label>
        <input
          name="last_name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          type="text"
        />

        {!!memberProp && memberProp.createdAt && (
          <p style={{ color: "gray" }}>
            Medlem siden {formatDate(memberProp.createdAt)}
          </p>
        )}

        <label htmlFor="gender">Kjønn</label>
        <select
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          name="gender"
        >
          <option value="" disabled>
            Velg kjønn
          </option>
          <option value="Mann">Mann</option>
          <option value="Kvinne">Kvinne</option>
        </select>

        <label htmlFor="birthday">Fødselsdato</label>
        <input
          name="birthday"
          value={birthday.toISOString().split("T")[0]}
          onChange={(e) => setBirthday(new Date(e.target.value))}
          type="date"
        />

        <label htmlFor="email">E-post</label>
        <input
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
        />

        <label htmlFor="phone">Telefon</label>
        <input
          name="phone"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
            if (e.target.value === "81549300") setGameModalActive(true);
          }}
          type="text"
        />

        <label htmlFor="address">Adresse</label>
        <input
          name="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          type="text"
        />

        <label htmlFor="allergies">Allergier</label>
        <input
          name="allergies"
          value={allergies}
          onChange={(e) => setAllergies(e.target.value)}
          type="text"
        />

        <label htmlFor="voice_group">Stemmegruppe</label>
        {["Vet ikke", "Sopran", "Alt", "Tenor", "Bass"].map((vg) => (
          <div key={vg}>
            <input
              name="voice_group"
              checked={voiceGroup === vg}
              value={vg}
              onChange={(e) => setVoiceGroup(e.currentTarget.value)}
              type="radio"
            />{" "}
            {vg}
          </div>
        ))}

        {!!memberProp && (
          <>
            <label htmlFor="active">Aktiv</label>
            <input
              type="checkbox"
              checked={active}
              name="active"
              onChange={(e) => setActive(e.currentTarget.checked)}
            />
          </>
        )}

        <button type="submit" className="btn">
          Lagre
        </button>
      </form>
    </>
  );
};
