import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { IEvent } from "types";
import { IEventDbo } from "types/contractTypes";
import { useDatabase } from "./useDatabase";
import { mapEvent } from "./useEvent";

export const useEvents = (sortDescending: boolean = false) => {
  const [events, setEvents] = useState<IEvent[] | undefined>();
  const database = useDatabase();

  useEffect(() => {
    if (!database) return;

    const unsubscribe = onValue(ref(database, "events"), (snapshot) => {
      const eventsObject = snapshot.val();
      const events = Object.keys(eventsObject)
        .map((eventId) => mapEvent(eventId, eventsObject[eventId] as IEventDbo))
        .sort((a, b) =>
          sortDescending
            ? b.date.getTime() - a.date.getTime()
            : a.date.getTime() - b.date.getTime()
        );
      setEvents(events);
    });
    return unsubscribe;
  }, [database, sortDescending]);

  return events;
};
