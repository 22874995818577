import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { IEvent } from "types";
import { IEventDbo } from "types/contractTypes";
import { useDatabase } from "./useDatabase";

export const useEvent = (eventId: string | undefined) => {
  const [event, setEvent] = useState<IEvent | undefined>();
  const database = useDatabase();

  useEffect(() => {
    if (!eventId) {
      setEvent(undefined);
      return;
    }
    if (!database) return;

    const unsubscribe = onValue(
      ref(database, `events/${eventId}`),
      (snapshot) => {
        const eventDbo = snapshot.val() as IEventDbo;
        const event: IEvent = mapEvent(eventId, eventDbo);

        setEvent(event);
      }
    );
    return unsubscribe;
  }, [database, eventId]);

  return event;
};

export const mapEvent = (
  eventId: string | undefined,
  eventDbo: IEventDbo
): IEvent => {
  return {
    id: eventId!,
    date: new Date(eventDbo.date),
    title: eventDbo.title,
    type: eventDbo.type,
    attendants: eventDbo.attendants,
    nonAttendants: eventDbo.non_attendants,
  };
};
