import { updatePassword } from "firebase/auth";
import { useFirebaseAuth } from "hooks";
import { FormEvent, useState } from "react";

export const PasswordChangeForm = () => {
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [error, setError] = useState<string | undefined>();

  const auth = useFirebaseAuth();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!auth?.currentUser) return;

    updatePassword(auth.currentUser, passwordOne)
      .then(() => {
        setPasswordOne("");
        setPasswordTwo("");
        setError(undefined);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

  return (
    <form onSubmit={handleSubmit}>
      <input
        name="passwordOne"
        value={passwordOne}
        onChange={(e) => setPasswordOne(e.target.value)}
        type="password"
        placeholder="Nytt passord"
      />

      <input
        name="passwordTwo"
        value={passwordTwo}
        onChange={(e) => setPasswordTwo(e.target.value)}
        type="password"
        placeholder="Bekreft nytt passord"
      />

      <button disabled={isInvalid} type="submit" className="btn">
        Endre passord
      </button>

      {error && <p>{error}</p>}
    </form>
  );
};
