import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { IMember } from "types";
import { IMemberDbo } from "types/contractTypes";
import { useDatabase } from "./useDatabase";

export const useMembers = (
  activeOnly: boolean = true
): IMember[] | undefined => {
  const [members, setMembers] = useState<IMember[] | undefined>();
  const database = useDatabase();

  useEffect(() => {
    if (!database) return;

    const unsubscribe = onValue(ref(database, "members"), (snapshot) => {
      const membersObject = snapshot.val();
      let members = Object.keys(membersObject).map((memberId) => {
        const memberDbo = membersObject[memberId] as IMemberDbo;

        const member: IMember = {
          id: memberId,
          active: memberDbo.active,
          address: memberDbo.address,
          allergies: memberDbo.allergies,
          birthday: new Date(memberDbo.birthday),
          createdAt: new Date(memberDbo.created_at),
          email: memberDbo.email,
          firstName: memberDbo.first_name,
          lastName: memberDbo.last_name,
          gender: memberDbo.gender,
          phone: memberDbo.phone,
          voiceGroup: memberDbo.voice_group,
        };
        return member;
      });

      if (activeOnly) {
        members = members.filter((m) => m.active);
      }

      members = members.sort((a, b) => {
        const aFullName = `${a.firstName} ${a.lastName}`;
        const bFullName = `${b.firstName} ${b.lastName}`;

        if (aFullName < bFullName) return -1;
        if (aFullName > bFullName) return 1;
        return 0;
      });

      setMembers(members);
    });
    return unsubscribe;
  }, [database, activeOnly]);

  return members;
};
