import * as ROUTES from "constants/routes";
import { useAuthUser } from "hooks";
import { ReactChild } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { IAuthUser } from "types";

export interface IProps {
  condition?: (user: IAuthUser) => boolean;
  children: ReactChild;
}
export const RequireAuth = ({ children, condition }: IProps) => {
  const [user] = useAuthUser();
  const location = useLocation();

  if (!user) {
    return <Navigate to={ROUTES.SIGN_IN} state={{ from: location }} replace />;
  }

  if (!!condition && !condition(user)) {
    return <Navigate to={ROUTES.HOME} replace />;
  }

  return <>{children}</>;
};
