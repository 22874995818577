import { Spinner } from "components";
import { Modal } from "components/Modal";
import { useDatabase, useEvents } from "hooks";
import { useState } from "react";
import { IEvent } from "types";
import { createEvent } from "utils/data";
import { formatDate } from "utils/dates";
import EventForm from "./EventForm";

interface IEventSelectorProps {
  onEventSelect: (id: string) => void;
}
export const EventSelector = ({ onEventSelect }: IEventSelectorProps) => {
  const [modalActive, setModalActive] = useState(false);
  const [editEvent, setEditEvent] = useState<IEvent | undefined>(undefined);
  const events = useEvents(true);
  const database = useDatabase();

  const createNewRehearsal = async () => {
    if (!database) return;
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);

    if (events?.find((e: IEvent) => e.date === today && e.type === "Øvelse")) {
      if (
        !window.confirm(
          "Det finnes allerede en øvelse i dag. Vil du likevel opprette en ny?"
        )
      ) {
        return;
      }
    }

    const eventId = await createEvent(database, {
      id: "",
      title: "Korøvelse",
      date: today,
      type: "Øvelse",
    });

    onEventSelect(eventId);
  };

  const handleEventEdit = (event: IEvent) => {
    setEditEvent(event);
    setModalActive(true);
  };

  return (
    <div className="event-selector">
      <Modal active={modalActive} onClose={() => setModalActive(false)}>
        <>
          <EventForm
            event={editEvent}
            onSubmit={(event) => {
              if (!!event) {
                onEventSelect(event.id);
              }
              setModalActive(false);
              setEditEvent(undefined);
            }}
          />
        </>
      </Modal>
      <div className="event-selector__button-bar">
        <button className="btn" onClick={createNewRehearsal}>
          Ny øvelse i dag
        </button>
        <button
          className="btn"
          onClick={() => {
            setModalActive(true);
            setEditEvent(undefined);
          }}
        >
          Nytt arrangement
        </button>
      </div>
      {!events && <Spinner />}
      {!!events && (
        <EventList
          events={events}
          onEventSelect={onEventSelect}
          onEventEdit={handleEventEdit}
        />
      )}
    </div>
  );
};

interface IEventListProps {
  events: IEvent[];
  onEventSelect: (eventId: string) => void;
  onEventEdit: (event: IEvent) => void;
}
const EventList = ({ events, onEventSelect, onEventEdit }: IEventListProps) => {
  return (
    <table className="table-full-width table-hor-lines-between table-last-td-right">
      <thead>
        <tr>
          <th>Dato</th>
          <th>Tittel</th>
          <th className="desktop-only">Type</th>
          <th className="desktop-only">Antall oppmøtte</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {events.map((event) => (
          <EventListElement
            event={event}
            key={event.id}
            onEventSelect={onEventSelect}
            onEventEdit={onEventEdit}
          />
        ))}
      </tbody>
    </table>
  );
};

interface IEventListElementProps {
  event: IEvent;
  onEventSelect: (eventId: string) => void;
  onEventEdit: (event: IEvent) => void;
}
const EventListElement = ({
  event,
  onEventSelect,
  onEventEdit,
}: IEventListElementProps) => {
  return (
    <tr>
      <td>{formatDate(event.date)}</td>
      <td>{event.title}</td>
      <td className="desktop-only">{event.type}</td>
      <td className="desktop-only">
        {!!event.attendants ? Object.keys(event.attendants).length : "0"}
      </td>
      <td>
        {/* <button
                    onClick={() => onEventEdit(event)}
                    className="btn btn-secondary btn-small"
                >
                    <i className="fas fa-edit" />
                </button> */}
        <button
          onClick={() => onEventSelect(event.id)}
          className="btn btn-small"
        >
          Velg
        </button>
      </td>
    </tr>
  );
};

export default EventSelector;
