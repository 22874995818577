import { ReactChild, useState } from "react";
import { onAuthStateChanged, User } from "firebase/auth";

import { AuthUserContext } from "contexts";
import { IAuthUser } from "types";
import { useEffect } from "react";
import { useFirebaseAuth } from "hooks/useFirebaseAuth";
import { get, ref } from "firebase/database";
import { useDatabase } from "hooks";

interface IProps {
  children: ReactChild;
}
export const AuthUserHandler = ({ children }: IProps) => {
  const [user, setUser] = useState<IAuthUser | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);
  const auth = useFirebaseAuth();
  const database = useDatabase();

  useEffect(() => {
    if (!auth || !database) {
      return;
    }

    onAuthStateChanged(
      auth,
      async (authUser: User | null) => {
        if (!database) {
          return;
        }

        if (!authUser) {
          setUser(undefined);
        } else {
          const domainUser: IAuthUser = {
            uid: authUser.uid,
            email: authUser.email,
            name: "",
            permissions: {},
          };
          const dbUser = (
            await get(ref(database, `users/${authUser.uid}`))
          ).val();
          const permissions = (
            await get(ref(database, `roles/${dbUser.role}/permissions`))
          ).val();

          setUser({ domainUser, ...dbUser, permissions });
          localStorage.setItem("authUser", JSON.stringify(domainUser));
        }
        setLoaded(true);
      },
      () => {
        setUser(undefined);
        localStorage.removeItem("authUser");
      }
    );
  }, [setUser, auth, database]);

  return (
    <>
      <AuthUserContext.Provider value={[user, loaded]}>
        {children}
      </AuthUserContext.Provider>
    </>
  );
};
