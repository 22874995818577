import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "components/auth/RequireAuth";
import {
  Allergies,
  Home,
  SignIn,
  Registration,
  TopList,
  AttendanceOverview,
  InactiveMembers,
  Members,
  Payment,
  MailingList,
  SemesterStatistics,
  Roles,
  Users,
  Account,
  ForgottenPassword,
  SignUp,
  DataExport,
  Plots,
} from "routes";
import { IAuthUser } from "types";
import * as ROUTES from "constants/routes";
import * as PERMISSIONS from "constants/permissions";

export const RoutesList = () => {
  return (
    <Routes>
      <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
      {authenticatedRoute(ROUTES.LANDING, <Home />)}
      {authenticatedRoute(
        ROUTES.REGISTRATION,
        <Registration />,
        (authUser) => !!authUser.permissions[PERMISSIONS.EVENTS_WRITE]
      )}
      {authenticatedRoute(
        ROUTES.TOP_LIST,
        <TopList />,
        (authUser) =>
          !!authUser.permissions[PERMISSIONS.MEMBERS_READ] &&
          !!authUser.permissions[PERMISSIONS.EVENTS_READ] &&
          !!authUser.permissions[PERMISSIONS.SEMESTERS_READ]
      )}
      {authenticatedRoute(
        ROUTES.ALLERGIES,
        <Allergies />,
        (authUser) => !!authUser.permissions[PERMISSIONS.MEMBERS_READ]
      )}
      {authenticatedRoute(
        ROUTES.ATTENDANCE_OVERVIEW,
        <AttendanceOverview />,
        (authUser) => !!authUser.permissions[PERMISSIONS.EVENTS_WRITE]
      )}
      {authenticatedRoute(
        ROUTES.INACTIVE_MEMBERS,
        <InactiveMembers />,
        (authUser) =>
          !!authUser.permissions[PERMISSIONS.MEMBERS_READ] &&
          !!authUser.permissions[PERMISSIONS.EVENTS_READ]
      )}
      {authenticatedRoute(
        ROUTES.MEMBERS,
        <Members />,
        (authUser) => !!authUser.permissions[PERMISSIONS.MEMBERS_WRITE]
      )}
      {authenticatedRoute(
        ROUTES.PAYMENT,
        <Payment />,
        (authUser) => !!authUser.permissions[PERMISSIONS.SEMESTERS_WRITE]
      )}
      {authenticatedRoute(
        ROUTES.MAILING_LIST,
        <MailingList />,
        (authUser) => !!authUser.permissions[PERMISSIONS.MEMBERS_READ]
      )}
      {authenticatedRoute(
        ROUTES.SEMESTER_STATISTICS,
        <SemesterStatistics />,
        (authUser) =>
          !!authUser.permissions[PERMISSIONS.EVENTS_READ] &&
          !!authUser.permissions[PERMISSIONS.SEMESTERS_READ]
      )}
      {authenticatedRoute(
        ROUTES.ROLES,
        <Roles />,
        (authUser) => !!authUser.permissions[PERMISSIONS.ROLES_WRITE]
      )}
      {authenticatedRoute(
        ROUTES.USER_LIST,
        <Users />,
        (authUser) => !!authUser.permissions[PERMISSIONS.USERS_READ]
      )}
      {authenticatedRoute(ROUTES.ACCOUNT, <Account />)}
      {authenticatedRoute(ROUTES.PASSWORD_FORGET, <ForgottenPassword />)}
      <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
      {authenticatedRoute(
        ROUTES.DATA_EXPORT,
        <DataExport />,
        (authUser) =>
          !!authUser.permissions[PERMISSIONS.MEMBERS_READ] &&
          !!authUser.permissions[PERMISSIONS.EVENTS_READ] &&
          !!authUser.permissions[PERMISSIONS.SEMESTERS_READ]
      )}
      {authenticatedRoute(
        ROUTES.PLOTS,
        <Plots />,
        (authUser) => !!authUser.permissions[PERMISSIONS.MEMBERS_READ]
      )}
    </Routes>
  );
};

const authenticatedRoute = (
  path: string,
  element: JSX.Element,
  condition?: (user: IAuthUser) => boolean
) => (
  <Route
    path={path}
    element={<RequireAuth condition={condition}>{element}</RequireAuth>}
  />
);
